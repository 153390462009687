html {
  scroll-behavior: smooth;
}
.react-player {
  padding-top: 56.25%; 
  position: relative;
}

.react-player>div {
  position: absolute; 
}