@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;



* {
  font-family: "Raleway"
}

.custom-shape-divider-bottom-1707815190 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1707815190 svg {
  position: relative;
  display: block;
  width: calc(300% + 1.3px);
  height: 500px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1707815190 .shape-fill {
  fill: #3652ad;
}

.custom-toast-container {
  /* Set background color to primary color */
  background-color: #3652ad;
  /* Set text color to white */
  color: white;
  /* Set font family */
  font-family: Arial, sans-serif;
}

/* Custom styles for individual toast elements */
.custom-toast {
  /* Set background color to secondary color */
  background-color: #fe7a36;
  /* Set text color to white */
  color: white;
  /* Add padding */
  padding: 10px 20px;
  /* Set border radius */
  border-radius: 8px;
  /* Set box shadow */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

@layer components {
  .custom-toast-progress {
    @apply bg-primary;
  }

  .Toastify__toast-icon>svg {
    /* fill: #3652ad; */
    @apply fill-primary;
  }

  .Toastify__toast-theme--light .Toastify__progress-bar {
    @apply bg-secondary;
  }
}

/* Custom styles for toast progress bar */


.Toastify__toast-body {
  background-color: #ffffff;
  /* Set background color to white */
  color: #333333;
  /* Set text color to black */
}



.light-theme .custom-toast {
  background-color: #f0f0f0;
  /* Set background color to light gray */
  color: #333333;
  /* Set text color to black */
}

.light-theme .custom-toast-progress {
  background-color: #333333;
  /* Set progress bar color to black */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.DialogOverlay[data-state='open'],
.DialogContent[data-state='open'] {
  animation: fadeIn 300ms ease-out;
}

.DialogOverlay[data-state='closed'],
.DialogContent[data-state='closed'] {
  animation: fadeOut 300ms ease-in;
}